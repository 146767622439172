module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/frankintarter/git/franklintarter/personal-website/src/templates/page-default.js","basic-pages":"/Users/frankintarter/git/franklintarter/personal-website/src/templates/page-basic.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-images","options":{"maxWidth":2048}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Franklin Tarter's Personal Website","short_name":"Franklin Tarter's Website","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"static/img/logo.png","legacy":true,"include_favicon":true,"crossOrigin":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KQG5W65"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
